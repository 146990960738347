import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import AlbumLinks from '../AlbumLinks';

import styles from './album-details.module.css';

const AlbumDetails = ( { artist, backLink, body, links, frontCover, title } ) => {
	return (
		<div className={ styles.album }>
			<header className={ styles.header }>
				<span>
					<h1>{ title }</h1>
					<h2>{ artist }</h2>
				</span>
				{ backLink
					? <Link
						className={ `${ styles.backLink } more-link` }
						to="/music"
					>
						<small>&larr; all music</small>
					</Link>
					: null
				}
			</header>
			<div className={ styles.wrapper }>
				<div className={ styles.info }>
					<Img
						className={ styles.albumCover }
						fluid={ frontCover }
					/>
					<AlbumLinks
						className={ styles.albumLinks }
						links={ links }
					/>
				</div>
				<div className={ styles.details }>
					<div
						className="body"
						dangerouslySetInnerHTML={ { __html: body } }
					/>
				</div>
			</div>
		</div>
	);
};

AlbumDetails.propTypes = {
	artist: PropTypes.string.isRequired,
	backLink: PropTypes.bool,
	body: PropTypes.string.isRequired,
	frontCover: PropTypes.object.isRequired,
	links: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired
};

export default AlbumDetails;
