import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import AlbumDetails from '../../components/AlbumDetails';
import Layout from '../../components/Layout';

import styles from './album-page-template.module.css';

const AlbumPageTemplate = ( { data } ) => {
	const {
		file: {
			childMarkdownRemark: {
				frontmatter: {
					frontCover: { childImageSharp: { fluid: frontCover } },
					// backCover: { childImageSharp: { fluid: backCover } },
					...album
				},
				html: body
			}
		}
	} = data;

	return (
		<Layout>
			<section className={ styles.album }>
				<AlbumDetails
					backLink
					body={ body }
					frontCover={ frontCover }
					{ ...album }
				/>
			</section>
		</Layout>
	);
};

AlbumPageTemplate.propTypes = { data: PropTypes.object.isRequired };

export default AlbumPageTemplate;

export const query = graphql`
  query AlbumPageTemplate($relativeDirectory: String!) {
    file(relativeDirectory: {eq: $relativeDirectory}) {
			childMarkdownRemark {
      	frontmatter {
					title
					artist
					frontCover {
						childImageSharp {
							fluid(maxWidth: 500) {
								...GatsbyImageSharpFluid
							}
						}
					}
					backCover {
						childImageSharp {
							fluid(maxWidth: 500) {
								...GatsbyImageSharpFluid
							}
						}
					}
					links {
						type
						title
						url
					}
      	}
				html
			}
    }
  }
`;
