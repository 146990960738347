import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './album-links.module.css';

const AlbumLinks = ( { className, links } ) => {
	const mapLinks = useCallback( () => links.map( ( { type, title, url } ) => {
		if ( type === 'link' ) {
			return (
				<a
					className="more-link"
					href={ url }
					key={ title }
					target="_blank"
					rel="noreferrer noopener"
				>
					<small>{ title }</small>
					{/* <img
						src={ platforms[ title ] }
					/> */}
				</a>
			);
		}

		// handle custom
		return null;
	} ), [ links ] );

	return (
		<div className={ `${ styles.albumLinks } ${ className }` }>
			{ mapLinks() }
		</div>
	);
};

AlbumLinks.defaultProps = {
	className: '',
	links: []
};

AlbumLinks.propTypes = {
	className: PropTypes.string,
	links: PropTypes.array
};

export default AlbumLinks;
